<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
/**
 * Advanced-table component
 */

export default {
  page: {
    title: "งานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    btnComponent,
    Multiselect
  },
  data() {
    return {
      title: "งานนอก",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ศูนย์บริการ",
        },
        {
          text: "งานนอก",
        },
      ],

      /*error: "please input data",
      Testpost: false,
      testpost:{
        name:"",
        job:"",
      },*/
      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],
      branchIdSearch:[],
      filter: [
        {
          nameTh: "",
          brands:"",
          vehicleModel:"",
        },
      ],
      osId:'',

      optionsBrand:[],
      optionsVehicles:[],

      fields: [
        
        {
          key: "osCode",
          sortable: true,
          label: "รหัสงานนอก",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่องานนอก (ไทย)",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "บริษัท",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา(บาท)",
          tdClass: "text-right"
        },
        {
          key: "action",
          label: "แก้ไข | ลบ",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },

  created() {
    this.getLocalData();
    this.getData();
    
  },
  methods: {
    getLocalData(){
      
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch=[];
      this.localData.forEach( item => {
        localDataBranch.push(item.branchId)
      })
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // console.log(this.localDataBranch);

    },
    handleSearch() {
      this.getData();
    },

    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/outsourcing-service", {					
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: [this.branchId],
            nameTh: this.filter.nameTh,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          this.rowData = response.data.data; //?ข้อมูล
          this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          // console.log(response.data.data[0]);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    ConfirmDelPackage(rowData) {
      Swal.fire({
        title: "ต้องการลบข้อมูลงานนอกใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deletePackage(rowData);
        }
      });
    },

    deletePackage: function(rowData) {
      this.osId = rowData;
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/outsourcing-service/delete", {
          data: {
            osId: this.osId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    onSearch(){
      if(this.branchIdSearch == '' || this.branchIdSearch == undefined || this.branchIdSearch.length == 0){
        this.branchId = this.localData[0].branchId;
      }else{
        this.branchId = this.branchIdSearch.branchId;
      }
      // console.log(this.branchId);
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">ข้อมูลแพคเกจในระบบ</h4> -->
            
            <div class="row">
              <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="branchId"><code>* </code>สาขา</label><br />
                    <multiselect
                      v-model="branchIdSearch"
                      :options="localData"
                      label="nameTh"
                    >
                    </multiselect>
                  </div>
                </div>
              <div class="col-md-3">
                <label>&nbsp;</label>
                ชื่องานนอก :
                <b-form-input
                  v-model="filter.nameTh"
                  type="search"
                  placeholder="Search..."
                  @keyup.enter="handleSearch"
                  class="form-control  ms-2"
                ></b-form-input>
              </div>
              
              <div class="col-md-5 text-end">
                <label>&nbsp;</label><br>
                <btnComponent @click="onSearch" changeStyle="search"></btnComponent>&nbsp;
                <router-link :to="{ name: 'form-outsourcing-service' }">
                  <button class="btn btn-primary"><i class="uil uil-plus"> </i>เพิ่มงานนอก</button>
                </router-link>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="handlePageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>
            </div>
            <!-- End search -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <tableData :fields="fields" :items="rowData">
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'edit-outsourcing-service',
                          params: { osId: rowData.item.osId },
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="ConfirmDelPackage(rowData.item.osId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </tableData>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.total }}  รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
